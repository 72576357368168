<template>
  <div class="responsive-font-height flex flex-col items-center px-5 lg:px-0 w-full md:w-4/5 xl:w-1/3">
    <div class="flex items-end justify-between top-nav-group w-full">
      <button @click="navBarShow" class="back-button">Back</button>
      <p class="heading">Available Stacks</p>
    </div> 
    <section v-for="(stack, index) in allStacks" :key="stack.stackName" class="allstacks-list">

    <Stack :selected='stack.selected'
           :index='index'
           :points='stack.points'
           :expiration='stack.expiration'
           :stackname='stack.stackName'
           :is-add-mode='true'
           />
    
    </section>
    
  </div>
</template>

<script>
import Stack from '../molecules/Stack'


export default {
  name: 'AllStacks',
  
  data: function() {
    return {
      
    }
  },

  mounted() {
    this.$store.state.navBarShow = false
  },
  
  methods: {
  navBarShow(){
    // this.$store.state.navBarShow = true
    this.$router.push('/Home')
  }
    
},

  computed: {
    allStacks() {
      return this.$store.getters.allStacks
    }

  },
  
  components: {
    Stack
  }
}
</script>


<style lang="scss">

.allstacks-list{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // padding-top: 0.2em;
  // padding-bottom: 0.2em;
  padding-left: 1em;
  padding-right:1em;
  width:100%;
  margin-top:0.3em;
  height: 4.4em;
  // background-color: var(--stack-background-color);
  border-radius: 0.8em;
  border: 0.15em solid var(--stack-background-color);

}

.top-nav-group {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.heading {
  color: white;
  font-weight: bold;
  font-size: 1.5em
}

.test {
  content: ''
}

.back-button {

  background-color: white;
  padding-right: 1.5em;
  padding-left: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  // margin-right: 1em;
  border-radius: 0.5em;
  font-weight: bold;
  color: var(--background-color);
  user-select: none;
  outline: none;
  
   &:focus {
	outline: none;
	}

}

.responsive-font-height {
  font-size: var(--responsive-font-height)
}

</style>
