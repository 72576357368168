<template>
  <div class="prize-won flex flex-col items-center justify-center ">
    
       <p class="prize-font-size">{{ name }}</p>
       <p class="exp-font-size">{{ expire }}</p>
    
  </div>
</template>


<script>


export default {
  name: 'Introduction',
  props: {

    /*
    points: {
      type: Number,
      required: false/true
      default() {
        return 999999;
      }
    },
    */

    expire: String,
    name: String,
    index: Number,
    

    
  },
  components: {
  },
  computed: {
  },
  methods: {
    
  }

}
</script>

<style lang="scss">

.prize-font-size {
  font-size: 0.9em;
  font-weight: 600;
 
}

.exp-font-size {
  font-size: 0.7em;
  margin-top: -0.4em
}


.prize-won {
  // border: 1px var(--secondary-color) solid;
  // margin-top: 0.2em;
  height: 4em;
  width: 100%;
  border-radius: 0.8em;
  // background-color: var(--accent-color);
  color: white;
  font-size: var(--responsive-font-height);
  font-weight: normal;
  box-shadow: inset -2px -2px 8px rgba(46,206,255,1),
              inset -2px -2px 12px rgba(0,61,99,1),
              inset 2px 4px 4px rgba(46,206,255,0.1),
              inset 2px 2px 8px rgba(0,61,99,1);
  // transition: transform .2s ease-in-out;
  // cursor: pointer;
  
  // &:not(.opacity_50):hover {
  //   transform: scale(0.98, 0.98);
   
  // }   
  
  // &:focus {
	// outline: none;
	// // border-radius: 3em;
	// }
	
  // &:not(.opacity_50) {
  //   cursor: not-allowed;
  // }   
}

// .comp__stack:hover {
//   transform: scale(0.95, 0.95);
// }


</style>
