<template>
  <div class="w-full px-5 lg:px-0 md:w-4/5 xl:w-1/3">
    <transition @enter="disableLookAtPrizes" appear>
    <h1 class="text-2xl text-white mb-6">Your Prizes</h1>
    </transition>
    <section v-for="(prize, index) in userPrizes" :key="prize.name" class="w-full mt-1">

    <Prize :index='index'
           :expire='prize.expire'
           :name='prize.name'
           />
    
    </section>


  </div>
</template>

<script>
// import { component } from 'vue/types/umd'
import Prize from "../molecules/PrizeWon.vue"

export default {
  
  data() {
    return {

    }
  },

components: {
  Prize
},

methods: {
  disableLookAtPrizes(){
    this.$store.state.lookAtPrizes = false
  }
    
},

computed: {

  userPrizes() {
   return this.$store.getters.userPrizes
  }

}



}


</script>

<style lang="scss">



</style>