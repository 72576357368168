<template>   
    <component :is="animation"></component> 
</template> 

<script>
import AnimationSegOne from "../views/AnimationSegOne"
import AnimationSegTwo from "../views/AnimationSegTwo"

export default {
  
data(){
  return {
    animation : 'AnimationSegOne'
  }

},

components: {

 AnimationSegOne,
 AnimationSegTwo
},

mounted() {
  setTimeout(()=>{
    this.animation = 'AnimationSegTwo'
    this.$store.state.startJewelAnimation = true
   setTimeout(()=>{
     this.$router.push('/Home')
  }, 5000)

  
  }, 4500)


}



}


</script>