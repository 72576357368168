<template>
  <div class="flex justify-center items-center w-full overflow-hidden content">
    <div id="container"></div>

    <section class="flex justify-center items-center w-full h-full introducing-jewels">
      <div id="loader" class="">
      </div>
     <p class="introducing-telkom">Introducing <span class="block font-bold">Telkom Jewels</span></p>
  
    </section>

<!--     
    <div id="container">
      <div id="collect">

      </div>
    </div> -->
    


  </div>
</template>

<script>
// import { component } from 'vue/types/umd'
// import Prize from "../molecules/PrizeWon.vue"

export default {
  
  data() {
    return {
      // selectJewel: [ require("../../src/assets/ruby.png") ,require("../../src/assets/sapphire.png") , require("../../src/assets/emerald.png")],
      selectJewelCSS: [ 'jewel01' ,'jewel02' , 'jewel03'],
      jewelHeight: 1
    }
  },

components: {
  
},

mounted() {
  this.$store.state.startJewelAnimation = false
  this.$store.state.showLogo = false
  this.$store.state.navBarShow = false
  console.log('test mount')
  this.collectAnimationJewels()
  
    setTimeout(() => {
        // this.$router.push('/Home')
       
        
        // console.log('removed')
      }, 10000);

  },

methods: {
  navBarShow(){
    this.$store.state.navBarShow = true
    this.$router.push('/')
  
  },  

  collectAnimationJewels() {
    var interval = setInterval(() => {
      const collect = document.getElementById('loader')
      const jewel = document.createElement('span')
      var size = Math.floor(Math.random() * 10)
      var randomJewel = Math.floor(Math.random() * 3)
      
      jewel.setAttribute("src", this.jewel[randomJewel])
      // document.documentElement.style.setProperty('--randomJewel', 'url(' + this.selectJewel[randomJewel] + ')');
       console.log(this.selectJewelCSS[randomJewel])
      jewel.setAttribute("width", size + 90 + 'em')
      jewel.setAttribute("height", size + 90 + 'em')
     
      jewel.setAttribute("class", this.selectJewelCSS[randomJewel] + ' jewels-rotate-animation-class')
            
      collect.appendChild(jewel)
      console.log(collect.childElementCount)
   
    
      var collectSize = collect.childElementCount
      if (collectSize > 9) {
        
        clearInterval(interval)
        
        console.log(collect.children[1].style)
        // var testVar = 0
        
        for (var i = 0; i < collect.childElementCount; i += 1) {
        
        (function(i) {
             var randomSpeed = Math.random()
            //  var StopAnimation = randomSpeed * 10000
             
             setInterval(function() {
    
             collect.children[i].style.top = randomSpeed + 'px'
              randomSpeed += randomSpeed * 0.1

                for(var j = 0; j < collect.childElementCount; j++) {
                collect.children[j].style.webkitAnimationPlayState = "paused";
               }

              
              // if(randomSpeed > StopAnimation) {
                
               
              //   clearInterval(goingUpInterval)
               
              //  for(var j = 0; j < collect.childElementCount; j++) {
                // collect.children[j].style.webkitAnimationPlayState = "paused";
              //   collect.children[j].classList.remove("jewels-rotate-animation-class")  ;
              //   collect.children[j].classList.add("jewels-falldown-animation-class")  ;
              //   collect.children[j].style.webkitAnimationPlayState = "running";
               
              //   console.log(collect.children[j])
                
              //   }
                
              // }
              



             }, 10)
        })(i);
        }
        
        
        
        }     
         
      // setTimeout(() => {
      //       jewel.remove()
      //       // console.log('removed')
      //     }, 15000);
  
    }, 300);   
  }

  
 

},

computed: {
 jewel(){
   return this.$store.getters.selectJewel
 }

}



}


</script>

<style lang="scss">



:root {
  --randomJewel: url("../../src/assets/sapphire.png");
  
}


.test123 {
  animation: introTelkom 2s ease-in infinite alternate;
}

.introducing-telkom {
  position: absolute;
  animation: introTelkom 4s ease-in forwards;
  font-size: 0.8em;
  // background-color: var(--spinwheel-background);
  padding: 1em;
  border-radius: 0.5em;
  // box-shadow: 0 0 0.1em 0;
}

@keyframes introTelkom {
  0%{
   opacity: 0; 
  }
  
  10%{
   opacity: 1; 
  }
  
  90%{
   opacity: 1; 
  }
  
  100%{
   opacity: 0; 
  }
  
}


.introducing-jewels {

  background-color: var(--spinwheel-background);
  animation: changebgcolor 2s ease-in forwards;
  // width: 50%;
  // height: 50%;
  
}

@keyframes changebgcolor {

  0%{
    background-color: white;
  }
  
  100%{
    background-color: var(--spinwheel-background);
  }
  
}


#loader {
  position: relative;
  width: 18em;
  height: 18em;
  // overflow: hidden;
  border-radius: 50%;
  transform: translate(0%,0%);
  transform-origin: center;
  // animation: scaleCircle 5s ease-in
  
}

@keyframes scaleCircle {
  0% {
    transform: scale(0.2,0.2);
  }
    
  100% {
 
    transform: scale(1,1);
  }


}


.jewels-falldown-animation-class {
  animation: jewelsDrop 3s linear forwards;
}

.jewels-rotate-animation-class {
  animation: rotateJewels 3s linear infinite forwards;
}


@keyframes jewelsDrop {

  0% {
    transform:translateY(0px);
  }
  
  100% {
    transform:translateY(1700px);
  }
  
}

.jewel01 {
  position: absolute;
  z-index: 3;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  // background-color: purple;
  transform: rotate(calc(36deg * var(--i)));
  // animation: rotateJewels 3s linear infinite forwards;
  &::before {
  content: '';
  
  background-image:  url('../../src/assets/sapphire.png');
  background-size: 100% 100%;
  transform-origin: center;
  position: absolute;
  top:0;
  left:0;
  width: 2em;
  height: 2em;
  opacity: 0.8;
  animation: jewelIntroAnimation 3s ease-out forwards;
  // background-color:black;
 
  }

}

.jewel02 {
  position: absolute;
  z-index: 2;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  // background-color: purple;
  transform: rotate(calc(36deg * var(--i)));
//  animation: rotateJewels 3.5s linear infinite forwards;
  &::before {
  content: '';
  
  background-image:  url('../../src/assets/ruby.png');
  background-size: 100% 100%;
  transform-origin: center;
  position: absolute;
  top:0;
  left:0;
  width: 1em;
  height: 1em;
  opacity: 0.8;
  animation: jewelIntroAnimation 3s ease-out  forwards;
  // background-color:black;
 
  }

}

.jewel03 {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  // background-color: purple;
  transform: rotate(calc(36deg * var(--i)));
//  animation: rotateJewels 4s linear infinite forwards;
  &::before {
  content: '';
  
  background-image:  url('../../src/assets/emerald.png');
  background-size: 100% 100%;
  transform-origin: center;
  position: absolute;
  top:0;
  left:0;
  width: 1.5em;
  height: 1.5em;
  opacity: 0.8;
  animation: jewelIntroAnimation 3s ease-out forwards;
  // background-color:black;
 
  }

}




#collect {
  
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
  height: 100%;
  width: 100%;
  // overflow: hidden;
  animation: rotateJewels 5s linear infinite forwards
  // transform:rotate(45deg)
}

@keyframes rotateJewels {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }

}

#container {
  
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%,-50%);
  
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: var(--responsive-font-height);
}


.jewel-animation {
  position: absolute;
  left: 50%;
  top: 50%;
  // transform: translate(-50%,-50%);
  animation: jewelIntroAnimation 5s ease-in-out forwards;
  // filter: blur(var(--randomBlur));
}

@keyframes jewelIntroAnimation {
  
  0% {
    transform: scale(0) translateY(0%) rotate(0deg);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
 
  90% {
    opacity: 1;
    
  }

  100%{
    transform: scale(1) translateY(0%) rotate(360deg);
    opacity: 1;  
  }
}

</style>