<template>
  <div class="flex flex-col justify-center items-center w-full spinwheel-background h-full">
	<p v-if="spinAgainBtn || swopWheeltoModal" class="model-for-prize">{{prize}}</p>
		<div id="css_wheel">
			<img src="../../src/assets/TelkomSpinWheel_01.png" alt="" class="absolute">
			
			<div id="seg1">{{wheelPosition[0].name}}</div>
			<div id="seg2">{{wheelPosition[1].name}}</div>
			<div id="seg3">{{wheelPosition[2].name}}</div>
			<div id="seg4">{{wheelPosition[3].name}}</div>
			<div id="seg5">{{wheelPosition[4].name}}</div>
			<div id="seg6">{{wheelPosition[5].name}}</div>
			<div id="seg7">{{wheelPosition[6].name}}</div>
			<div id="seg8">{{wheelPosition[7].name}}</div>
			
			
		</div>
				

		<div id="pointer" />
			<!-- <img src="../../src/assets/TelkomSpinWheelPointer_01.png" alt="" class="absolute" > -->
		<button v-on:click="wheelSpin" v-if="spinAgainBtn" class="spinAgainBtn" > SPIN({{spins}}) 
			<div class="rotate-circle-spin-btn"></div>
		</button>
		<button v-if="swopWheeltoModal" class="returnToHomeBtn" v-on:click="returnToHome">Click to return home</button>
	</div>

</template>


<script>

export default {
  name: 'AllStacks',
  
  data: function() {
    return {
		spin : true,
		test : 200,
		spinAgainBtn : false,
		swopWheeltoModal : false,
		prizeWon: ''
	}
  },
	mounted(){
		this.wheelSpin()
	},
	
	computed: {
		spins() {
			return this.$store.getters.availableSpins
		},

		wheelPosition() {
			return this.$store.getters.wheelPosition

		},
	
		prize() {
			return this.prizeWon
		},
		
		availablePrizes() {
			return this.$store.getters.availablePrizes
		}
	}
	,
  
	components: {
    
  },

  methods: {
	
	addPrizeToList(wheelposition) {
		var userPrizes = this.$store.state.userPrizes
		var availabePrizes = this.$store.state.availablePrizes
		// var lookAtPrizes = this.$store.state.lookAtPrizes

		if(wheelposition == 1) {
			userPrizes.push(availabePrizes[0])
			this.$store.state.lookAtPrizes = true
		}
		else if(wheelposition == 3) {
			userPrizes.push(availabePrizes[1])
			this.$store.state.lookAtPrizes = true
		}
		else if(wheelposition == 5) {
			userPrizes.push(availabePrizes[3])
			this.$store.state.lookAtPrizes = true
		}
		else if(wheelposition == 7) {
			userPrizes.push(availabePrizes[2])
			this.$store.state.lookAtPrizes = true
		}
	},

	returnToHome() {
	this.$router.push('/Home')
    this.$store.state.navBarShow = true;
	},

	// randomPrize() {this.$store.dispatch('randomPrize')},
	randomPrize() {
		return Math.floor(Math.random() * 8)}
		,
	
	beforeEnter(el) {
     
     this.test = 200
     el.style.webkitTransform = 'rotate(' + this.test  + 'deg)'
	console.log(el.style.webkitTransform)
	},
	
	showSpinBtn() {
		this.$store.dispatch('removeASpin')
			console.log('inside spinWheel' + this.spins)
		
		if (this.spins > 0) {
			this.spinAgainBtn = true
			
		} else {
			this.swopWheeltoModal = true
		}
	},
	
	wheelSpin()	{
		this.spinAgainBtn = false
		const prize = this.randomPrize()
		const check = document.getElementById("css_wheel")
		// check.style.Transform = 'rotate(' + 0 + 'deg)'
		// console.log(check.style)
		console.log(prize)
		
		console.log('randomprize ====' + this.wheelPosition[prize].name)
		// console.log ('randomized number=====' + this.randomPrize())
		var spinToDegree = this.wheelPosition[prize].deg + 720
		
		
		console.log(spinToDegree)
		let rotation = 0
		// console.log(el.style.width)
		const interval = setInterval(() => {
			check.style.transform = 'rotate(-' + rotation  + 'deg)'
			rotation+=4
			// console.log(round)
			if (rotation > spinToDegree) {
					check.style.transform = 'rotate(-' + spinToDegree  + 'deg)'
					this.prizeWon = this.wheelPosition[prize].name
					this.addPrizeToList(prize)
					clearInterval(interval)
					this.showSpinBtn()
					console.log('here')
					// done()
					
				}
		}, 1);
		console.log(check.style.width)

		// check.style.width = '300px'
		
		
	}
  
  }

}
</script>


<style lang="scss">


.spinWheel-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform:translate(-50%,-12.5em);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
}

#pointer {
	position: absolute;
	width: 6.7em;
	height: 6.7em;
	background-color: var(--background-color);
	border-radius: 50%;

	&::before {
		content: '';
		position: absolute;
		transform: translate(-50%,-100%) ;
		top: 20%;
		left: 50%;
		border-bottom: 3em var(--background-color) solid ;
		border-right: 2em transparent solid ;
		border-left: 2em transparent solid ;
	}
}

#css_wheel {
	position: absolute;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19em;
	height: 19em;
	// background-color: white;
	// border-radius: 50%;
	// border: 0.4em solid white;
	// transform-origin: center;

}

#seg1 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(22.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	
	
	
}

#seg2 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(67.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 

}

#seg3 {
	position: absolute;
	top:50%;
	left:50%;
	
	
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(112.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	
}

#seg4 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(157.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	
}

#seg5 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(202.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	

}

#seg6 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(247.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	

}


#seg7 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(292.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	

}

#seg8 {
	position: absolute;
	top:50%;
	left:50%;
	transform-origin: bottom;
	transform:translate(-50%,-100%) rotate(337.5deg);
	color:white;
	line-height: 1em;
	font-size: 0.6em;
	width: 6em;
	height: 11em;
	font-weight: bold; 
	

}

.returnToHomeBtn {
	position: absolute;
	background-color: white;
	padding: 1em;
	border-radius: 2em;
	color: var(--background-color);
	font-weight: 600;
	font-size: 1em;
	animation: scaleUpDown 0.4s ease-in-out 1s infinite alternate;

	&:focus {
		outline: none;
		border-radius: 3em;
	}
}


.model-for-prize{
	position: absolute;
	font-size: 1.2em;
	font-weight: 600;
	background-color: var(--background-color);
	color:white;
	padding: 1.2em;
	border-radius: 1.5em;
	margin-top: 0%;
	margin-left:0%;
	animation: modal-animate-in .2s ease-out forwards
}

@keyframes modal-animate-in {
	0%{
		opacity: 0;
		transform: translate(0%,0%)
	}
	
	100%{
		opacity: 1;
		transform: translate(0%,-300%)
	}
	
}

.spinAgainBtn {
	position: absolute;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	align-self: center;
	height: 5.5em;
	width: 5.5em;
	color: var(--spinwheel-background);
	// background-color: var(--spin-button-color);
	border-radius: 4em;
	font-weight: 600;
	font-size: 1em;
	outline: none;
	// animation: scaleUpDown 0.2s ease-in-out 1s infinite alternate;
	box-shadow: -2px -4px 8px rgba(46,206,255,1),
              -2px -4px 12px rgba(0,61,99,1),
              inset 2px 4px 4px rgba(46,206,255,0.1),
              2px 2px 8px rgba(0,61,99,1);
    
	// &:hover {
    // box-shadow: inset -2px -4px 8px rgba(46,206,255,1),
    //           inset -2px -4px 12px rgba(0,61,99,1),
    //           inset 2px 4px 4px rgba(46,206,255,0.1),
    //           inset 2px 2px 8px rgba(0,61,99,1);
    // transform: scale(0.98);
	// }
	
	&:focus {
		outline: none;
		border-radius: 3em;
	}
}
	
.rotate-circle-spin-btn {
  position: absolute;
  width:5.1em;
  height:5.1em;
  border-left: 0.2em solid var(--spin-button-color);
  border-top: 0.2em solid var(--stack-background-color);
  border-bottom: 0.2em solid var(--stack-background-color);
  border-right: 0.2em solid var(--spin-button-color);
  border-radius: 50%;
  background-color: transparent;
  animation: rotate-quarter-circle 1.5s linear infinite;
}
.spinWheel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;

}

.spinwheel-background {

// background-color: var(--spinwheel-background);
font-size: var(--responsive-font-height);
user-select: none;
}

@keyframes scaleUpDown {
  
  from{
    transform: scale(1,1);
  }
  
  to{
    transform: scale(0.9, 0.9);
  }

}


</style>