<template>
    <div class="flex flex-col justify-center items-center intro-animation-segment-two-bg content h-full">
     <h1 id="stack-em">STACK 'EM and SPIN!</h1>
     <!-- <div class="flex justify-center absolute">

       <button class="neo-test"><img src="../../src/assets/sapphire.png" alt="" class="w-auto p-4"></button>
       <button class="neo-test">+</button>
       <button class="neo-test">+</button>
     </div> -->

    </div>
</template>

<script>
export default {
data()   {
  return {

  }
},

methods: {
  addJewel(){

    
  }

}


}

</script>


<style lang="scss">


.neo-test {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  background-color: transparent;
  border-radius: 50%;
  margin: 0.4em;
    font-weight: bold;
  font-size: 2em;
  color: white;
  box-shadow: -2px -4px 8px rgba(46,206,255,1),
              -2px -4px 12px rgba(0,61,99,1),
              inset 2px 4px 4px rgba(46,206,255,0.1),
              2px 2px 8px rgba(0,61,99,1);
              
  &:hover {
    box-shadow: inset -2px -4px 8px rgba(46,206,255,1),
              inset -2px -4px 12px rgba(0,61,99,1),
              inset 2px 4px 4px rgba(46,206,255,0.1),
              inset 2px 2px 8px rgba(0,61,99,1);
    transform: scale(0.98);
    // color: transparent;
    
  }

}




#stack-em {
animation: moveDown 3s ease-out forwards;
font-size: 2em;
font-weight: bold;
color: white
}

@keyframes moveDown {
   0% {
    transform: translate(0em,-0.8em);
    opacity: 0;
   }
   
   20% {
    transform: translate(0em,0em);
    opacity: 1;
   }
   
   80% {
    transform: translate(0em,0em);
    opacity: 1;
   }
      
   100% {
    transform: translate(0em,1em);
    opacity: 0;
   }
    
}


.intro-animation-segment-two-bg {
animation: changeToBlue 0.3s ease-out forwards;


}


@keyframes changeToBlue {

  0%{
    background-color:var(--spinwheel-background);
  }
  
  50%{
    background-color: var(--background-color);
  }
  50%{
    background-color: transparent;
  }
  
}

</style>